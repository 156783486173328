<!--授权，从这边跳转到后端，然后后端带着参数跳转到这个页面，后续每一个axios都需要设置cookie-->
<template>
    <div></div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  import { getCookie } from '../../utils/cookie'

  export default {
    name: 'Auth',
    mixins: [globalConfig],
    mounted () {
      if (getCookie('userAuth') == 1) {
        // 已经授权了，在router 里面已经前置判断 next()
      } else {
        // 前往授权不代理url。去获取真实的session
        const url = process.env.VUE_APP_AUTH_URL
        window.location.replace(url)
      }
    }
  }
</script>

<style scoped>

</style>
